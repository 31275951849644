import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router) { }

  array = [1, 2, 3, 4, 5, 6, 7];

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.router.navigateByUrl("GameFrame");
    }
  }

}
