<div nz-row nzJustify="center" nzAlign="middle" style="height: 100%">
  <div style="display:flex;flex-direction: column;align-items: center;justify-content: center;">
    <img src="assets/QuickConnectLogo.png" class="login-top-logo"/>
    <div nz-col>
      <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()" style=" margin-top: 0px !important;">
        <p>
          <nz-form-control nzErrorTip="Please input your username!">
            <mat-form-field appearance="outline">
              <mat-label>Username</mat-label>
              <input autocomplete="off" matInput formControlName="userName"/>
              <mat-icon matSuffix>mail</mat-icon>
            </mat-form-field>
          </nz-form-control>
        </p>
        <p>
          <nz-form-control nzErrorTip="Please input your Password!">
            <mat-form-field appearance="outline">
              <mat-label>Password</mat-label>
              <input autocomplete="off" [class]="hidePassword ? 'hidePassword' : ''" matInput formControlName="password" [type]="'text'"/>
              <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            </mat-form-field>
          </nz-form-control>
        </p>
        <button mat-raised-button class="login-form-button login-form-margin" [nzType]="'primary'" [nzLoading]="isLoggingIn" *ngIf="!isLogginError && !isNoResponse">Log in</button>
        <button mat-raised-button class="login-form-button login-form-margin" nzType="default" nzDanger *ngIf="isLogginError" (click)="clearError()">Incorrect user / pass</button>
        <button mat-raised-button class="login-form-button login-form-margin" nzType="default" nzDanger *ngIf="isNoResponse" (click)="clearError()">Can't connect to server</button>
      </form>
    </div>
  </div>
</div>