import { Component, OnInit } from '@angular/core';
import { PointBundle } from '../../models/pointBundle';
import { SelfServeService } from '../../services/self-serve.service';

@Component({
  selector: 'app-purchase-gallery',
  templateUrl: './purchase-gallery.component.html',
  styleUrls: ['./purchase-gallery.component.scss']
})
export class PurchaseGalleryComponent implements OnInit {

  isLoading = true;
  pointBundles: PointBundle[];

  constructor(private selfServeService: SelfServeService) { }

  ngOnInit(): void {
    this.selfServeService.getPointBundles().subscribe((data) => {
      this.isLoading = false;
      this.pointBundles = data.sort((n1, n2) => {
        if (n1.rmPrice > n2.rmPrice) {
          return 1;
        }

        if (n1.rmPrice < n2.rmPrice) {
          return -1;
        }

        return 0;
      });
    }, (err) => {
      this.isLoading = false;
    });
  }

}
