import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthService } from '../../../services/auth.service';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  validateForm!: FormGroup;
  isLoggingIn = false;
  isLogginError = false;
  isNoResponse = false;
  hidePassword = true;

  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      this.isLoggingIn = true;
      var val = this.validateForm.value;
      var retUrl = this.route.snapshot.queryParamMap.get("ReturnUrl");
      this.authService.login(val.userName, val.password, retUrl).subscribe((res: any) => {
        var data = res.body;
        if (data && data.isOk) {
          window.location = data.redirectUrl;
        }
      }, (err) => {
        if (err?.status === 0) {
          this.isLoggingIn = false;
          this.isNoResponse = true;
          setTimeout(() => {
            this.isNoResponse = false;
          }, 2000);
        } else {
          this.isLoggingIn = false;
          this.isLogginError = true;
          setTimeout(() => {
            this.isLogginError = false;
          }, 2000);
          //this.createMessage('Wrong user / password', 'error');
        }
      });
    }

  }

  registerClicked() {
    this.router.navigateByUrl("Register" + window.location.search);
  }

  clearError() {
    this.isLogginError = false;
    this.isNoResponse = false;
  }

  constructor(private fb: FormBuilder, private authService: AuthService, private route: ActivatedRoute, private router: Router, private message: NzMessageService) { }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      userName: [null, [Validators.required]],
      password: [null, [Validators.required]]
    });
  }

  createMessage(content: string, type: string): void {
    this.message.create(type, content, {});
  }

}
