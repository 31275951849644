<nz-list nzGrid class="Grid" [nzLoading]="isLoading">
  <div nz-row [nzGutter]="16">
    <div nz-col [nzXXl]="6" [nzXl]="6" [nzLg]="6" [nzMd]="6" [nzSm]="12" [nzXs]="24" *ngFor="let item of pointBundles">
      <nz-list-item>
        <nz-card [nzTitle]="item.name" [nzExtra]="extraTemplate">
          <nz-row [nzGutter]="16">
            <nz-col [nzSpan]="16">
              <nz-statistic [nzValue]="item.socialPoints" [nzTitle]="'Social'" [nzPrefix]="socialTpl"></nz-statistic>
              <ng-template #socialTpl><i nz-icon nzType="crown"></i></ng-template>
            </nz-col>
            <nz-col [nzSpan]="8">
              <nz-tag [nzColor]="'gold'" *ngIf="item.displayLabel === 'BestValue'">Best Value!</nz-tag>
              <nz-tag [nzColor]="'red'" *ngIf="item.displayLabel === 'Hot'">Hot!</nz-tag>
            </nz-col>
          </nz-row>
          <nz-row [nzGutter]="16">
            <nz-col [nzSpan]="16">
              <nz-statistic [nzValue]="item.sweepsPoints" [nzTitle]="'Sweeps'" [nzPrefix]="sweepsTpl"></nz-statistic>
              <ng-template #sweepsTpl><i nz-icon nzType="gift"></i></ng-template>
            </nz-col>
            <nz-col [nzSpan]="8">
              <button nz-button nzType="default"><i nz-icon nzType="shopping-cart"></i>Buy</button>
            </nz-col>
          </nz-row>
        </nz-card>
        <ng-template #extraTemplate>
          <nz-statistic [nzValue]="item.rmPrice" [nzTitle]="priceTitleTpl" [nzPrefix]="priceTpl"></nz-statistic>
          <ng-template #priceTpl><i nz-icon nzType="dollar"></i></ng-template>
          <ng-template #priceTitleTpl></ng-template>
        </ng-template>
      </nz-list-item>
    </div>
  </div>
</nz-list>
