import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class ConfigLoaderService {

  public apiBase = '';
  public authBase = '';

  constructor(private httpClient: HttpClient) { }

  initialize() {
    return this.httpClient.get<AppConfig>('./assets/config/config.json')
      .pipe(tap((response: AppConfig) => {
        this.apiBase = response.apiBase;
        this.authBase = response.authBase;
      })).toPromise<AppConfig>();
  }

}

export interface AppConfig {
  apiBase: string;
  authBase: string;
}
