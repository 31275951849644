<nz-carousel nzAutoPlay class="carouselMain">
  <!--<div nz-carousel-content>
    <img class="carouselImg"
         src="../assets/1.jpg"
         alt="" />
  </div>
  <div nz-carousel-content>
    <img class="carouselImg"
         src="../assets/2.jpg"
         alt="" />
  </div>-->
</nz-carousel>
