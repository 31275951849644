import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { User, UserManager } from 'oidc-client';
import { environment } from '../../environments/environment';
import { ConfigLoaderService } from './config-loader-service.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  userManager: UserManager;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
    withCredentials: true,
    observe: 'response' as 'response'
  };

  base = this.configLoaderService.authBase;
  @Output() authStateChanged: EventEmitter<boolean> = new EventEmitter();

  private user: User = null;

  constructor(private _http: HttpClient, private configLoaderService: ConfigLoaderService) {
    const settings = {
      authority: this.configLoaderService.authBase,
      client_id: 'SLNGApp',
      redirect_uri: `${window.location.origin}/AuthCallback`,
      silent_redirect_uri: `${window.location.origin}/assets/silent-callback.html`,
      post_logout_redirect_uri: `${window.location.origin}`,
      response_type: 'code',
      scope: 'openid profile email SLNGApi',
      popupWindowTarget: 'loginFrame',
      automaticSilentRenew: true
    };
    this.userManager = new UserManager(settings);
    this.userManager.getUser().then(user => {
      this.user = user;
      this.authStateChanged.emit(this.user != null);
    });
  }


  public getUser(): User {
    return this.user;
  }

  public isLoggedIn(): boolean {
    return this.user != null && !this.user.expired;
  }

  public loginRedirect() {
    return this.userManager.signinRedirect();
  }

  public loginFrame() {
    return this.userManager.signinPopup();
  }

  public getAuthorizationHeaderValue(): string {
    return this.user != null ? `${this.user.token_type} ${this.user.access_token}` : '';
  }

  public register(firstName: string, lastName: string, email: string, password: string) {
    return this._http.post(this.base + '/Account/Register', JSON.stringify({ firstName, lastName, password, email }), this.httpOptions);
  }

  public verifyCode(code: string, email: string, returnUrl: string) {
    return this._http.post(this.base + '/Account/VerifyCode', JSON.stringify({ code, email, returnUrl }), this.httpOptions);
  }

  public login(username: string, password: string, returnUrl: string) {
    return this._http.post(this.base + '/Authentication/Login', JSON.stringify({ username, password, returnUrl }), this.httpOptions);
  }

  public silent(): Promise<User> {
    return this.userManager.signinSilent();
  }

  public signinSilentCallback() {
    return this.userManager.signinSilentCallback().then(us => {
      this.user = us;
      this.authStateChanged.emit(this.user != null);
    });
  }

  public signinRedirectCallback(): Promise<void> {
    return this.userManager.signinRedirectCallback().then(user => {
      this.user = user;
      this.authStateChanged.emit(this.user != null);
    });
  }

  public renewToken(): Promise<User> {
    return this.userManager.signinSilent();
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }
}
