<div nz-row>
  <div nz-col nzFlex="auto"></div>
  <div nz-col nzFlex="3">
    <h3 class="verify-form">We just sent a code to {{email}}, please enter it below</h3>
  </div>
</div>
<div nz-row>
  <div nz-col nzFlex="auto"></div>
  <div nz-col nzFlex="2">
    <form nz-form [nzLayout]="'inline'" [formGroup]="validateForm" (ngSubmit)="submitForm()">
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input the code">
          <nz-input-group nzPrefixIcon="lock">
            <input formControlName="code" nz-input placeholder="Verification code" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control>
          <button nz-button nzType="primary" [disabled]="!validateForm.valid" [nzLoading]="isVerifying" *ngIf="!isError">Verify</button>
          <button nz-button class="login-form-button login-form-margin" nzType="default" nzDanger *ngIf="isError" (click)="clearError()">Incorrect code</button>

        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</div>

