import { Component, OnDestroy, OnInit } from '@angular/core';
import { OidcClientNotification, OidcSecurityService, PublicConfiguration } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { PlayerService } from './services/player.service';
import { Player } from './models/player';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(public oidcSecurityService: OidcSecurityService, private router: Router, private authService: AuthService, private playerService: PlayerService) { }
  title = 'CodeName Casino';
  isCollapsed = true;
  isAuthenticated = false;
  isLogin = false;
  menuClosed = true;
  player: Player;

  async ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isLogin = event.url.startsWith("/Login") || event.url.startsWith("/Register");
      }
    });

    this.authService.authStateChanged.subscribe(isAuth => {
      this.isAuthenticated = isAuth;
      if (isAuth)
        this.playerService.getPlayer().subscribe(data => {
          this.player = data;
        });
      else this.player = null;
    });
  }

  openDrawer() {
    this.menuClosed = false;
  }

  closeDrawer() {
    this.menuClosed = true;
  }

  goHome() {
    this.router.navigateByUrl('/');
  }

  login() {
    //this.router.navigateByUrl('LoginFrame');
    this.authService.loginRedirect();
  }

  logout() {
    this.authService.logout();
  }

}
