import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Player } from '../models/player';
import { AuthService } from './auth.service';
import { ConfigLoaderService } from './config-loader-service.service';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  base = this.configLoaderService.apiBase;

  constructor(private _http: HttpClient, private _auth: AuthService, private configLoaderService: ConfigLoaderService) {

  }

  getPlayer() {
    var httpOptions = {
      headers: new HttpHeaders({
        Authorization: this._auth.getAuthorizationHeaderValue(),
      }),
    };

    return this._http.get<Player>(this.base + 'me', httpOptions);
  }
}
