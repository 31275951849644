import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-verify-code',
  templateUrl: './verify-code.component.html',
  styleUrls: ['./verify-code.component.scss']
})

export class VerifyCodeComponent implements OnInit {

  email: string;
  retUrl: string;

  validateForm!: FormGroup;

  isVerifying = false;
  isError = false;


  submitForm(): void {

    this.isVerifying = true;

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (this.validateForm.valid) {
      var val = this.validateForm.value;

      this.authService.verifyCode(val.code, this.email, this.retUrl).subscribe((res: any) => {
        window.location.href = this.retUrl;
      }, (err) => {
        this.isVerifying = false;
        this.isError = true;
        setTimeout(() => {
          this.isError = false;
        }, 2000);
      });

    }
  }

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router, private route: ActivatedRoute) {
    var state = this.router.getCurrentNavigation().extras.state;
    this.email = state.email;
    this.retUrl = state.retUrl;
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      code: [null, [Validators.required]]
    });
  }

}
