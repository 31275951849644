import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { PointBundle } from '../models/pointBundle';
import { AuthService } from './auth.service';
import { ConfigLoaderService } from './config-loader-service.service';

@Injectable({
  providedIn: 'root'
})
export class SelfServeService {

  base = this.configLoaderService.apiBase;

  constructor(private _http: HttpClient, private _auth: AuthService, private configLoaderService: ConfigLoaderService) {

  }

  getPointBundles() {
    var httpOptions = {
      headers: new HttpHeaders({
        Authorization: this._auth.getAuthorizationHeaderValue(),
      }),
    };

    return this._http.get<PointBundle[]>(this.base + 'SelfServe/GetPointBundles', httpOptions);
  }
}
